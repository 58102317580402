<template>
    <div class="config">
        <el-form ref="dialogForm" :model="form" label-width="120px">
            <el-row>
                <el-form-item label="包邮门槛费" prop="freeShippingPrice">
                    <el-input type="text" v-model="form.freeShippingPrice" placeholder="请输入包邮门槛费"></el-input>
                </el-form-item>
                <el-form-item label="扫码获得积分数" prop="jifen">
                    <el-input type="text" v-model="form.jifen" placeholder="请输入扫码获得积分数">
                    </el-input>
                </el-form-item>
                <el-form-item label="是否开发版本">
                    <el-radio-group v-model="form.isDevelop">
                        <el-radio label="true">是</el-radio>
                        <el-radio label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" @click="submitData()">确定提交</el-button>
                </el-form-item>
            </el-row>
        </el-form>
    </div>
</template>

<script>
export default {
    name: 'config',
    data() {
        return {
            form: {
                freeShippingPrice: "",
                jifen: "",
                isDevelop: 'true'
            }
        }
    },
    methods: {
        submitData() {
            this.getAjax('api/config/updateConfig', { ...this.form }, 'post').then(res => {
                this.message('提交成功');
            })
        }
    },
    mounted() {
        //获取配置表
        this.getAjax('api/config/findConfig', {}, 'get').then(res => {
            if(res.data){
                this.form = res.data;
            }
        })
    },
}
</script>

<style lang="less" scoped>
.config {
    width: 500px;
}
</style>